
<h1 class="list_title">
  <span class="text"> Recent Listings</span>
  <span (click)="goToProductList()" class="iconify-wrapper">

    <span>View all</span>
    <i class="fal fa-long-arrow-right"></i>
  </span>
</h1>


<div *ngIf="showLoader" class="loader">
  <mat-spinner></mat-spinner>
</div>
<div class="list">
  <app-product *ngFor="let prod of listings" [product]="prod"></app-product>
  <app-no-items-msg *ngIf="listings.length === 0" [text]="noItemText">

  </app-no-items-msg>



</div>

<div class="bottom-row" *ngIf="listings.length !== 0">
  <span (click)="goToProductList()" class="iconify-wrapper">

    <button mat-raised-button color="primary">View all</button>

  </span>
</div>
