<div [class.banner--view]="isOpen" class="banner">
  <span (click)="closePromo()" class="banner_close" aria-label="close" href="#">×</span>
  <div class="promo_content">

    <div class="banner_content">
      <div class="banner_text-wrap">
        <p class="banner_title">{{title }}</p>
        <p class="banner_text">{{ text}} </p>
      </div>

      <div class="banner_actions">
        <button (click)="closePromo()" routerLink="/posts/list-item/new" mat-raised-button color="accent">Post Now</button>
      </div>
    </div>
  </div>
</div>
