<div class="splash">
  <h1 class="splash_header">
    <span>
      We are better. <br> Together
    </span>
  </h1>
</div>
<div class="">
  <div class="loader"></div>
</div>

