import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-items-msg',
  templateUrl: './no-items-msg.component.html',
  styleUrls: ['./no-items-msg.component.scss']
})
export class NoItemsMsgComponent implements OnInit {
  @Input() text ;
  constructor() { }

  ngOnInit() {
  }

}
