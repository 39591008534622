import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogProfileLinksComponent } from 'src/app/dialogs/dialog-profile-links/dialog-profile-links.component';
import { ListingFilterComponent } from 'src/app/dialogs/listing-filter/listing-filter.component';
import { AuthService } from 'src/app/services/auth.service';
import { NavServiceService } from 'src/app/services/nav-service.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit, OnDestroy {
  user;
  profileDialogOpen: boolean;
  profileDialog: any;
  subs: Subscription[] = []
  @ViewChild('drawer') drawer;
  searchWord = "";
  constructor(private authService: AuthService, private router: Router, private dialog: MatDialog, private navService: NavServiceService, private productService: ProductsService) {

  }

  get showFilters() {
    return this.navService.showFilters;
  }

  get showModalFilter() {
    return this.navService.showModalFilters;
  }

  ngOnInit() {
    let userSub = this.authService.user$.subscribe(user => {
      this.user = user;
    })
    this.subs.push(userSub)
  }

  ngAfterViewInit() {
    this.navService.drawer = this.drawer;
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  @HostListener("window:click", ["$event"]) onClickEvent(event) {
    const matDrawer = event.target.closest("mat-drawer-container");
    if (matDrawer) {
      return;
    } else {
      this.drawer.close();
    }
  }

  get loggedIn() {
    return this.authService.isLoggedIn();
  }

  login(initialScreen) {
    this.authService.login(initialScreen);
  }

  toggleProfileMenu(el: HTMLDivElement) {
    const coordinates = el.getClientRects()[0];
    if (this.profileDialogOpen) return this.profileDialog.close();
    this.profileDialogOpen = true;
    this.profileDialog = this.dialog.open(DialogProfileLinksComponent,
      {
        position:
          { left: ((coordinates.left - 150) + 'px'), top: (coordinates.top + 15) + 'px' },
      }
    )
    this.navService.profileDialog = this.profileDialog;
    let dialogSub = this.profileDialog.afterClosed().subscribe(x => {
      this.profileDialogOpen = false;
    })
    this.subs.push(dialogSub);

  }

  logout() {
    this.authService.logOut();
  }
  goToUserListing() {
    this.router.navigate(['/posts/user-listing']);
    this.drawer.close();
  }

  listItem(e: MouseEvent,) {
    e.preventDefault();
    this.router.navigate(["posts/list-item/new"]);
    this.drawer.close();
  }

  goToItems(e: MouseEvent) {
    e.preventDefault();

    this.router.navigate(["posts/all-items"]);
  }

  typeAheadSearch(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.search()
    }
  }
  search() {
    if (!this.navService.showModalFilters) {
      this.router.navigate(['/posts/all-items'], { queryParams: { search: this.searchWord } })
    } else {
      this.navService.search.next(this.searchWord);
    }

    this.searchWord = '';
  }


  openFilter() {
    this.navService.filter.next();
  }

}
