<div class="app-body">
 <app-nav-bar></app-nav-bar>
 <div class="pusher">
  <div class="promo" *ngIf="showSPromotion && isNotPostPage()">
    <app-promotion [text]="promotionText" [title]="promotionTitle"></app-promotion>
  </div>
  <router-outlet></router-outlet>
 </div>
 <app-footer></app-footer>
</div>


