import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavServiceService } from 'src/app/services/nav-service.service';

@Component({
  selector: 'app-dialog-profile-links',
  templateUrl: './dialog-profile-links.component.html',
  styleUrls: ['./dialog-profile-links.component.scss']
})
export class DialogProfileLinksComponent implements OnInit, OnDestroy {
  user: any;
  subs: Subscription[] = [];
  @Input() isDialog = true;

  constructor(private authService: AuthService, private router: Router, private navService: NavServiceService, public swPush: SwPush, private snackBar: MatSnackBar) {
    console.log(this.isDialog)
  }

  ngOnInit() {
    let userSub = this.authService.user$.subscribe(user => {
      if (user) this.user = user;
    })
    this.subs.push(userSub)
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  get loggedIn() {
    return this.authService.isLoggedIn();
  }

  login(initialScreen) {
    this.authService.login(initialScreen);
  }


  logout() {
    this.authService.logOut();
    if (this.navService.profileDialog) this.navService.profileDialog.close()
  }

  goToUserListing() {
    this.router.navigate(['/posts/user-listing']);
    this.navService.drawer.close();
    if (this.navService.profileDialog) this.navService.profileDialog.close()
  }

  goToUerGrid() {
    this.router.navigate(['/management/user-grid']);
    this.navService.drawer.close();
    if (this.navService.profileDialog) this.navService.profileDialog.close()
  }

  goToUserProfile() {
    this.router.navigate(['/posts/profile-form']);
    this.navService.drawer.close();
    if (this.navService.profileDialog) this.navService.profileDialog.close()
  }

  listItem(e: MouseEvent) {
    e.preventDefault();
    this.router.navigate(["posts/list-item/new"]);
    this.navService.drawer.close();
    if (this.navService.profileDialog) this.navService.profileDialog.close()
  }
  contactUs() {
    window.location.href = "mailto:frumsecondhand@gmail.com";
  }

  goToItems(e) {
    e.preventDefault();
    this.navService.drawer.close();
    this.router.navigate(["/posts/all-items"]);
  }


}
