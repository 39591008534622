import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit, AfterViewInit {
  @Output() close = new EventEmitter<boolean>()
  isOpen: boolean = true;
  @Input() title = '';
  @Input() text = '';
  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  closePromo() {
   this.isOpen = false;
  }

}
