import { Route } from '@angular/compiler/src/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ListingFilterComponent } from 'src/app/dialogs/listing-filter/listing-filter.component';
import { NavServiceService } from 'src/app/services/nav-service.service';
import { ProductsService } from 'src/app/services/products.service';
import { IProductSummary } from '../models/product-summary';



const filtersMapping = {
  take: '',
  skip: '',
  searchWord: 'Search word',
  locations: 'Location',
  catagories: 'Category'
};

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  searchWord;
  listings = [];
  noItemText = 'Loading...';
  get products(): IProductSummary[] {
    return this.productService.products.slice(0, 10) || [];
  }
  showLoader;

  constructor(private productService: ProductsService, private dialog: MatDialog, private router: Router, private snackbar: MatSnackBar, private navService: NavServiceService) { }

  ngOnInit() {
    this.fetchProds();
    this.navService.showFilters = true;
  }

  goToProductList() {
    this.router.navigate(["/posts/all-items"]);
  }





  async fetchProds() {
    this.showLoader = true;
    try {
      let prodSub = await this.productService.getRecentProds().toPromise() as any;
      this.listings = prodSub;
      this.showLoader = false;
      this.noItemText = "No items currently available"
    } catch (err) {
      this.noItemText = "Error connecting to server. Check your connection and try again"
      this.showLoader = false;
      this.snackbar.open('Error connecting to server. Check your connection and try again', 'Dismiss', { duration: 3000, panelClass: 'err-panel' })
    }

  }


  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
    this.navService.showFilters = false;
  }





}
