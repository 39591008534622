import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IProductSummary } from '../models/product-summary';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() product: IProductSummary;
  constructor(private router: Router, private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
  }

  get description() {
    let maxChars = 100;
    if(window.screen.width  < 400) maxChars = 30;
    return this.product.description.length < maxChars ? this.product.description : this.product.description.slice(0, maxChars) + "...";
  }

  get price() {
    if (this.product.status === "SOLD") return this.product.status;
    return this.product.price == 0 ? 'FREE' : this.currencyPipe.transform(this.product.price, 'USD');
  }

  get location() {
    return this.product.location.length > 1 ? 'Multiple locations' : this.product.location[0]
  }

  get locationToolTip() {
    return this.product.location.length > 1 ? this.product.location.join(', ') : '';
  }



  navigateToProd() {
    this.router.navigate(["/posts/all-items/" + this.product._id])
  }


}
