<mat-card (click)="navigateToProd()" style="padding: 22px;" class="listing">
  <div class="listing_image-wrap">
    <img class="listing_image" loading="lazy"
      [src]="product.galleryImages[0]?.imageUrl || '../../../assets/images/no-image.jpg'" (click)="navigateToProd()"
      alt="listing-main-img">
  </div>

  <div class="listing_info">
    <div class="listing_title">{{product.title}}</div>
    <div class="listing_desc">{{description}}</div>
    <div class="listing_location">
      <span [matTooltip]="locationToolTip" class="listing_location-text">
        <span class="listing_location-icon">
          <i class="fal fa-map-marker-alt"></i>
        </span> <span>{{location}} </span>
      </span>

    </div>


    <div class="listing_actions">
      <button (click)="navigateToProd()" mat-button class="listing_btn listing_btn--prim">View More</button>
      <button [class.listing_btn--sold]="price === 'SOLD'" mat-button
        class="listing_btn listing_btn--price">{{price}}</button>
    </div>
  </div>
</mat-card>
