import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseApi } from "../util/global-vars.js";


import faker from 'faker';
import { of, Subject } from 'rxjs';
import { IProductDetail } from '../components/models/product-detail';
import { IProductSummary } from '../components/models/product-summary';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {




  listingFilterChanged = new Subject();

  listingFilterParams = {
    take: 10,
    skip: 0,
    catagories: [],
    locations: [],
    searchWord: ""
  }




  products: IProductSummary[] = [];
  constructor(private http: HttpClient) {

  }

  resetFilters() {
    this.listingFilterParams = {
      take: 10,
      skip: 0,
      catagories: [],
      locations: [],
      searchWord: ""
    }
  }

  getProductDetail(listingId: any) {
    return this.http.get(`${baseApi}/api/product/${listingId}`);
  }

  updateListingAsFormData(formData, id) {
    const headers = new HttpHeaders(
      {
        'accept': 'application/json',
        'Accept-language': 'en-US;en;q=0.8',
      }
    );
    return this.http.post(`${baseApi}/api/product/${id}`, formData, { headers })
  }

  createListing(formData: FormData) {
    const headers = new HttpHeaders(
      {
        'accept': 'application/json',
        'Accept-language': 'en-US;en;q=0.8',
      }
    );
    return this.http.post(`${baseApi}/api/product`, formData, { headers })
  }


  updateListing(newListingToEdit, id) {
    return this.http.post(`${baseApi}/api/product/${id}`, newListingToEdit);
  }

  markListingAsSold(listing) {
    return this.http.post(`${baseApi}/api/product/${listing._id}`, { status: 'SOLD', title: listing.title });
  }

  markListingAsApproved(listing: any) {
    return this.http.post(`${baseApi}/api/product/${listing._id}`, { status: 'AVAILABLE', title: listing.title, sendApprovedEmail: true });
  }

  addViewCount(id: number) {
    return this.http.get(`${baseApi}/api//update-product-view-count/${id}`);
  }

  getRecentProds() {
    return this.http.get(`${baseApi}/api/recent-product`);
  }

  uploadThumbnail(formData) {
    const headers = new HttpHeaders(
      {
        'accept': 'application/json',
        'Accept-language': 'en-US;en;q=0.8',
      }
    );
    return this.http.post(`${baseApi}/api/uploadThumbnail`, formData, { headers });
  }

  getProds() {
    return this.http.post(`${baseApi}/api/products`, { filters: this.listingFilterParams });
  }

  getAdminProds() {
    return this.http.post(`${baseApi}/api/admin-products`, { filters: { ...this.listingFilterParams, take: null } });
  }

  removeListing(_id: any) {
    return this.http.post(`${baseApi}/api/deleteProduct`, { id: _id });
  }

}
