import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavServiceService {
  drawer;
  profileDialog;
  showFilters: any;
  showModalFilters: any;
  search = new Subject<string>();
  filter = new Subject();
  constructor() { }
}
