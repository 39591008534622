import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {baseApi} from "../util/global-vars";
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public authService: AuthService, public router: Router, private http: HttpClient, private snackBar: MatSnackBar) {

  }

  async canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot : RouterStateSnapshot) {

    try{
      const user = await this.http.put(`${baseApi}/api/userinfo`, null).toPromise() as any;

      if(!!user) {
        return true;
      }
      else {
        this.authService.login("login", routerStateSnapshot.url);
        return false;
      }
    } catch(err){
      this.authService.login("login",routerStateSnapshot.url);
        return false;
    }
  }
}

