<div class="mobile_links mobile_links--dialog">
  <div (click)="listItem($event)" class="mobile_link">
    <i class="fab fa-sellsy"></i>
    Post item for sale
  </div>
  <div *ngIf="loggedIn">
    <div (click)="goToUserProfile()" class="mobile_link">
      <i class="fal fa-user-circle"></i>
      Profile
    </div>

    <div *ngIf="user?.userInfo.isAdmin" (click)="goToUerGrid()" class="mobile_link">
      <i class="far fa-users"></i>
      Manage users
    </div>
    <div (click)="goToUserListing()" class="mobile_link">
      <i class="fal fa-list-ul"></i>
      Manage Listings
    </div>


    <div (click)="logout()" class="mobile_link">
      <i class="fal fa-sign-out-alt"></i>
      Sign Out
    </div>
  </div>

  <div *ngIf="!loggedIn">
    <div (click)="login('login')" class="mobile_link">
      <i class="fal fa-sign-in-alt"></i>
      Sign in
    </div>
    <div (click)="login('signUp')" class="mobile_link">
      <i class="fal fa-user-plus"></i>
      Sign up
    </div>


  </div>
  <div (click)="goToItems($event)" class="mobile_link mobile_link--contact">
    <i class="fal fa-hiking"></i>
    Explore items
  </div>
  <div (click)="contactUs()" class="mobile_link mobile_link--contact">
    <i class="fal fa-id-card"></i>
    Contact us
  </div>
</div>
