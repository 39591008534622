<div class="nav">
  <div routerLink="/home" class="nav_logo">
    <img height="30" width="30" src="./../../../assets/images/yadshnneya-Logo.png" alt="logo">
    Second Hand
  </div>
  <div class="nav_links">
    <a href="mailto:frumdsecondhand@gmail.com" class="nav_link">Contact us</a>
    <a routerLink="posts/all-items" routerLinkActive="nav_link--active" (click)="goToItems($event)" href=""
      class="nav_link">Explore Items</a>
    <a routerLink="posts/list-item/new" routerLinkActive="nav_link--active" (click)="listItem($event)" href=""
      class="nav_link">List Item </a>
  </div>
  <div class="nav_profile">
    <span (click)="toggleProfileMenu(loggedInNav)" class="nav_logged-in" *ngIf="loggedIn">
      <span [class.chevron-up]="profileDialogOpen">
        <i class="fal fa-chevron-down"></i>
      </span>

      <span #loggedInNav [ngStyle]="{'background-image': 'url(' + user?.userInfo.profileImage  + ')'}" class="nav_img">
        <span *ngIf="!user?.userInfo.profileImage" class="nav_profile-pic">
          <i class="fas fa-user"></i>
          </span>
      </span>

    </span>
    <span *ngIf="!loggedIn">
      <a (click)="login('login')" class="nav_link"> Sign in</a>
      <a (click)="login('signUp')" class="nav_link">Sign up</a>
    </span>

  </div>
  <div class="nav_mobile-nav">
    <mat-drawer-container class="mat-container" [hasBackdrop]="true" autosize>
      <span class="filters">
        <span *ngIf="showFilters" class="search">
          <input (keyup)="typeAheadSearch($event)" [(ngModel)]="searchWord" type="search">
          <i class="fal fa-search"></i>
        </span>
        <i *ngIf="showFilters && showModalFilter" (click)="openFilter()" class="fal fa-filter"></i>
        <span class="bars" (click)="drawer.toggle()">
          <i class="fas fa-bars"></i>
        </span>
      </span>

      <mat-drawer [position]="'end'"  class="mobile" #drawer>
        <div class="mobile_wrap">
          <div class="mobile_user-info">
            <div [ngStyle]="{'background-image': 'url(' + user?.userInfo.profileImage  + ')'}"
              class="nav_img mobile_user-profile">
              <i *ngIf="!user?.userInfo.profileImage" class="fal fa-user"></i>
            </div>
            <div class="mobile_user-name">{{user?.email || ''}}</div>
          </div>
          <app-dialog-profile-links></app-dialog-profile-links>
        </div>

      </mat-drawer>
    </mat-drawer-container>
  </div>

</div>
