import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import * as FullStory from '@fullstory/browser';
import { environment } from '../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  showSPromotion = false;
  promotionTitle = "";
  promotionText = '';
  user: any;
  isBrowser: boolean;

  constructor(private authService: AuthService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      FullStory.init({
        orgId: 'Z34FP',
        devMode: !environment.production
      });
    }
  }
  async ngOnInit() {
    if (this.isBrowser) {
      this.authService.retrieveAuthFromUrl();
      const settings = await this.authService.getSiteSettings().toPromise();
      this.showSPromotion = settings[0].showPromotion;
      this.promotionTitle = settings[0].promotionTitle;
      this.promotionText = settings[0].promotionText;
      let userSub = this.authService.userSubject.subscribe(user => {
        if (user) {
          this.user = user;
          FullStory.identify(user._id);
          FullStory.setUserVars({
            name: user.userInfo.fullName,
            email: user.email
          });

        }
      })
      if (this.user) userSub.unsubscribe()
    }

  }
  isNotPostPage(): boolean {
    if (this.isBrowser) {
      return !window.location.href.includes('list-item/new') && !window.location.href.includes('user-grid');
    } else {
      return false;
    }
  }
}
