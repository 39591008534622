import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { BehaviorSubject, Subject } from 'rxjs';
import { shareReplay, tap, filter } from 'rxjs/operators';
import { redirectUrl, baseApi } from '../util/global-vars';
const AUTH_CONFIG = {
  domain: "yad-shneeya.us.auth0.com",
  clientID: "xrJ0ID6oWVfU8Sy32M5U05HqaPyWLarL"
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {



  userSubject = new BehaviorSubject(undefined);
  user$ = this.userSubject.asObservable().pipe(
    filter(user => !!user)
  );
  auth0 = new auth0.WebAuth({
    clientID: AUTH_CONFIG.clientID,
    domain: AUTH_CONFIG.domain,
    responseType: "token id_token",
    redirectUri: redirectUrl
    // scope: 'openid profile'
  });
  currentUser: Object;
  listingFilterChanged = new Subject();

  listingFilterParams = {
    take: 10,
    skip: 0
  }

  listings;
  isBrowser: any;
  constructor(private router: Router, private http: HttpClient, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isLoggedIn()) {
      this.userInfo();
    }
  }

  login(initialScreen, routeToRedirect = "/home") {
    this.auth0.authorize({ initialScreen });
    localStorage.setItem("redirectTo", routeToRedirect);
  }


  retrieveAuthFromUrl() {
    this.auth0.parseHash((err, authResult) => {
      if (err) return console.log("An error accoured");
      else if (authResult && authResult.idToken) this.setSession(authResult);
      window.location.hash = '';
      this.userInfo()
    })
  }
  setSession(authResult) {
    if(!this.isBrowser) return;
    const todayTimeStamp = (new Date()).getTime();
    let expiresAt = (new Date(todayTimeStamp + authResult.expiresIn * (60000/ 60))).getTime();
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt));
  }

  getExpiration() {
    if(!this.isBrowser) return;
    const expires = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expires);
    return new Date(expiresAt);
  }

  resetFilters() {
    this.listingFilterParams.skip = 0;
  }

  updateUserInfo(userInfo) {
    return this.http.post(`${baseApi}/api/updateUserInfo`, userInfo).pipe(tap(user => {
      this.currentUser = user;
      this.userSubject.next(user)
    }))
  }
  logOut() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    this.currentUser = null;
    this.userSubject.next(null);
    this.router.navigate(['/home'])
  }

  isLoggedIn() {
    if( !this.isBrowser) return false;
    return localStorage.getItem("id_token")
  }
  userInfo() {
    return this.http.put(`${baseApi}/api/userinfo`, null).pipe(
      shareReplay(),
      tap(user => {
        this.currentUser = user;
        this.userSubject.next(user)
      })
    )
      .subscribe()
  }

  getSiteSettings() {
    return this.http.get(`${baseApi}/api/getSiteSetting`);
  }

  getUserGrid(){
    return this.http.get(`${baseApi}/api/getUserGrid`);
  }

  getProfileListings() {
    return this.http.post(`${baseApi}/api/profile-products`, { filters: this.listingFilterParams });
  }

  sendSubscriptionRequest(sub: PushSubscription, filters) {
    return this.http.post(`${baseApi}/api/subscribe-to-listings`, { sub, filters });
  }
}
