import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AppShellComponent } from './app-shell/app-shell.component';



const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "home",
    component: HomePageComponent
  },

  {
    path: "management",
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "posts",
    loadChildren: () => import('./posts/posts.module').then(m => m.PostsModule),
  },

  {
    path: 'shell', component: AppShellComponent
  },
  {
    path: "**",
    redirectTo: '/home',
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
